// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  overflow: hidden;
  position: relative;
}

.wheel {
  fill: rgba(22, 62, 106, 0.6);
  font-size: 100px;
  width: 100px;
  height: 100px;
  border-radius: 6em;
  text-align: center;
  animation: rolling 1s linear infinite;
}

.road {
  position: absolute;
  margin-top: -4px;
  width: 100%;
  height: 10px;
  border-radius: 5em;
  background-color: #38B34A; /* Cambié el color a un tono más claro */
  animation: road-move 2s linear infinite;
}

@keyframes rolling {
  to {
      transform: rotate(-360deg);
  }
}

@keyframes road-move {
  from {
      transform: translateX(-140%);
  }

  to {
      transform: translateX(100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB,EAAE,wCAAwC;EACnE,uCAAuC;AACzC;;AAEA;EACE;MACI,0BAA0B;EAC9B;AACF;;AAEA;EACE;MACI,4BAA4B;EAChC;;EAEA;MACI,2BAA2B;EAC/B;AACF","sourcesContent":[".loader {\n  overflow: hidden;\n  position: relative;\n}\n\n.wheel {\n  fill: rgba(22, 62, 106, 0.6);\n  font-size: 100px;\n  width: 100px;\n  height: 100px;\n  border-radius: 6em;\n  text-align: center;\n  animation: rolling 1s linear infinite;\n}\n\n.road {\n  position: absolute;\n  margin-top: -4px;\n  width: 100%;\n  height: 10px;\n  border-radius: 5em;\n  background-color: #38B34A; /* Cambié el color a un tono más claro */\n  animation: road-move 2s linear infinite;\n}\n\n@keyframes rolling {\n  to {\n      transform: rotate(-360deg);\n  }\n}\n\n@keyframes road-move {\n  from {\n      transform: translateX(-140%);\n  }\n\n  to {\n      transform: translateX(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
