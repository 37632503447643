// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.slide-container {
  overflow: hidden;
  width: 100%;
  height: 15vh;
  background-color: grey;
}

.slide-track {
  display: flex;
  width: 200%; /* El track ocupa el doble del ancho del contenedor */
  animation: slide 20s linear infinite;
  animation-timing-function: linear;
}

.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 20%; /* Cada tarjeta ocupa el 20% del contenedor */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW,EAAE,qDAAqD;EAClE,oCAAoC;EACpC,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,UAAU,EAAE,6CAA6C;AAC3D","sourcesContent":["@keyframes slide {\n  0% {\n    transform: translateX(0%);\n  }\n  100% {\n    transform: translateX(-50%);\n  }\n}\n\n.slide-container {\n  overflow: hidden;\n  width: 100%;\n  height: 15vh;\n  background-color: grey;\n}\n\n.slide-track {\n  display: flex;\n  width: 200%; /* El track ocupa el doble del ancho del contenedor */\n  animation: slide 20s linear infinite;\n  animation-timing-function: linear;\n}\n\n.custom-card {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: white;\n  width: 20%; /* Cada tarjeta ocupa el 20% del contenedor */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
