// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
    width: 280px;
    height: 370px;
    background: rgba(72, 75, 92, 0.15);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    border: 1px solid rgba(72, 75, 92, 0.075);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .imgContainer {
    width: 100%;
    height: 12em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #3d3c3c; /* Color de fondo gris más claro */;
    display: flex;
    align-items: top;
    justify-content: right;
  }
  
  .text {
    padding: 20px;
  }
  
  .skeleton-content {
    width: 100%;
    height: 20px;
    background: #3d3c3c; /* Color de fondo gris más claro */
    margin-bottom: 10px;
  }
  
  /* .skeleton-content:last-child {
    margin-bottom: 0;
  
  
 */
  .skeleton-buttons {
    width: 90px;
    height: 40px;
    background: #3d3c3c; /* Color de fondo gris más claro */
    margin-bottom: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/NoticiasDestacadas/ProductSkeleton/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kCAAkC;IAClC,kCAAkC;IAClC,0BAA0B;IAC1B,yCAAyC;IACzC,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,6BAA6B;IAC7B,mBAAmB,EAAE,kCAAkC;IACvD,aAAa;IACb,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB,EAAE,kCAAkC;IACvD,mBAAmB;EACrB;;EAEA;;;;EAIA;EACA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB,EAAE,kCAAkC;IACvD,mBAAmB;EACrB","sourcesContent":[".skeleton {\n    width: 280px;\n    height: 370px;\n    background: rgba(72, 75, 92, 0.15);\n    -webkit-backdrop-filter: blur(7px);\n    backdrop-filter: blur(7px);\n    border: 1px solid rgba(72, 75, 92, 0.075);\n    border-radius: 10px;\n    overflow: hidden;\n  }\n  \n  .imgContainer {\n    width: 100%;\n    height: 12em;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n    background: #3d3c3c; /* Color de fondo gris más claro */;\n    display: flex;\n    align-items: top;\n    justify-content: right;\n  }\n  \n  .text {\n    padding: 20px;\n  }\n  \n  .skeleton-content {\n    width: 100%;\n    height: 20px;\n    background: #3d3c3c; /* Color de fondo gris más claro */\n    margin-bottom: 10px;\n  }\n  \n  /* .skeleton-content:last-child {\n    margin-bottom: 0;\n  \n  \n */\n  .skeleton-buttons {\n    width: 90px;\n    height: 40px;\n    background: #3d3c3c; /* Color de fondo gris más claro */\n    margin-bottom: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
